
.ant-menu-item-selected
    background-color: white !important
    border-radius: 12px
    width: 260px !important
    margin-left: 15px !important
    height: 48px !important

.ant-layout-sider
    max-width: 300px !important
    flex: 0 0 300px !important

.ant-menu-item
    margin: 10px !important

.ant-menu-inline .ant-menu-item::after
    border-right: 0px !important

.mobile-sidebar-menu-selected
    background: white
    padding: 15px
    margin: 10px
    border-radius: 12px
    color: #00B3FF
    font-size: 16px
    width: 100%

.mobile-sidebar-menu
    padding: 15px
    margin: 10px
    border-radius: 12px
    color: #84818A
    font-size: 16px
    width: 100%

.ant-drawer-header
    background: #F1F3F4
