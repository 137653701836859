@import '../../aero.colors.sass'

.header-container
    display: flex
    justify-content: space-between
    height: 60px
    background-color: $color-white
    padding: 15px

.circular-header-dp
    width: 32px
    height: 32px
    border-radius: 50%
    position: relative
    overflow: hidden
    display: inline-block

    & img
        min-width: 100%
        min-height: 100%
        width: auto
        height: auto
        position: absolute
        left: 50%
        top: 50%
        -webkit-transform: translate(-50%, -50%)
        -moz-transform: translate(-50%, -50%)
        -ms-transform: translate(-50%, -50%)
        transform: translate(-50%, -50%)

@media screen and ( min-width:  1180px)
    .hamburger
        display: none
    .home-header
        display: none

@media screen and (max-width: 1180px)
    .header-username
        display: none
