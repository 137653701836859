@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&family=Spicy+Rice&display=swap')

.header-wrapper
    background: #ECEAEE
    border-radius: 18px
    padding: 30px
    justify-content: space-between

.maps-heading
    font-size: 16px
    color: #47464A
    margin-top: 30px

.two-ends-items
    display: flex
    justify-content: space-between
    margin-top: 10px

.card-head
    font-size: 16px
    font-weight: bold
    margin-top: 8px

.card-wrapper
    width: 244px
    height: 321px
    border: 1px solid #DCDBDD
    border-radius: 7px

.date-wrapper
    color: #84818A
    font-size: 12px
    position: relative
    bottom: 5px

.status-wrapper
    background: #80D9FF3B
    height: 32px
    width: 212px
    border: 1px solid #80D9FF
    border-radius: 52px
    font-size: 12px
    color: #47464A
    padding: 7px
    margin-top: 10px

.footer-wrapper
    display: flex
    justify-content: space-between
    background: #F5F5F5
    border-top: 1px solid #DCDBDD
    margin-top: 19px
    border-radius: 0px 0px 7px 7px

.demo-wrapper
    background: #F97855
    color: white
    width: 70px
    border-radius: 100px
    padding: 6px 10px 6px 10px
    text-align: center
    position: relative
    top: 50px
    left: 10px
    z-index: 2

.action-btn-asset
    position: relative
    bottom: 2px
    right: 10px

.process-header
    font-size: 16px
    font-weight: 600
    margin-bottom: 10px
    color: #47464A
    font-family: 'Red Hat Display'

.process-wrapper
    margin-bottom: 25px

.warning-banner-wrapper
    background: rgb(249, 120, 85)
    color: white
    border-radius: 12px
    padding: 15px
    margin-bottom: 25px
    display: flex

.process-summary-box
    height: auto
    border-radius: 8px
    border: 1px solid #ABA8B0
    flex: 1

.process-summary-box-cost
    height: auto
    border-radius: 8px
    border: 1px solid #ABA8B0
    flex: 1

.summary-head
    font-size: 18px
    color: #84818A
    font-weight: 700
    padding: 20px
    margin-left: 10px

.summary-item-wrapper
    height: 54px
    vertical-align: top

.summary-item
    display: flex
    justify-content: space-between
    color: #47464A
    font-size: 16px

.summary-item-label
    font-weight: 700
    margin-left: 10px

.summary-item-value
    font-weight: 400
    margin-right: 10px

.summary-item-divider
    position: relative
    bottom: 15px

.summary-box-footer-holder
    display: grid
    grid-template-rows: auto auto auto

.summary-box-footer
    display: flex
    justify-content: space-between
    background: #F5F5F5
    border-radius: 0px 0px 8px 8px
    padding: 18px 28px 18px 28px

.warning-banner-wrapper
    background: rgb(249, 120, 85)
    color: white
    border-radius: 12px
    padding: 26px
    margin-bottom: 25px

.summary-box-footer-value
    font-size: 16px
    color: #47464A

.see-info-box
    background: #F5F5F5
    height: 30px
    padding: 4px 0px 4px 0px
    border-radius: 8px
    text-align: center
    cursor: pointer

.header-free-trial-btn
    color: white
    height: 68px
    background: rgb(255, 171, 42)
    font-size: 20px
    font-weight: 700

@media screen and ( min-width:  1180px)
    .header-wrapper
        display: flex
        height: 139px
    .header-search
        width: 340px
        border-radius: 8px
    .new-map-btn
        margin-left: 15px
        width: 123px
    .process-summary-wrapper
        display: flex
        margin-top: 30px
        align-items: flex-start
        gap: 20px
    .process-summary-box
        width: 514px
    .process-summary-box-cost
        width: 514px
    .summary-pay-btn
        width: 229px
        margin-left: 15px
    .summary-back-btn
        width: 170px
    .summary-action-btn
        float: right
    .mobile-view
        display: none
    .web-form-input-item
        display: inline-block
        float: left
    .web-input-item
        display: block !important
        margin-bottom: 30px !important
    .header-free-trial-btn
        width: 228px
@media screen and (max-width: 1180px)
    .header-search
        border-radius: 8px
    .new-map-btn
        margin-top: 15px
        width: 100%
    .maps-wrapper
        margin: auto
        width: 80%
        padding: 10px
    .step-desc
        font-size: 12px
    .process-summary-wrapper
        margin-top: 30px
    .process-summary-box
        margin-bottom: 15px
    .subtotal-value
        width: 125px
        text-align: right
    .summary-action-btn
        display: flex
        justify-content: space-between
    .web-view
        display: none
    .mobile-input-items
        display: flex !important
        justify-content: space-between
    .header-free-trial-btn
        width: 100%
