.orbit
    border: 2px solid black
    border-radius: 50%
    width: 300px
    height: 300px
    margin: 60px auto
    animation: circle 4s linear infinite

.planet
    background: #fe019a
    border-radius: 50%
    width: 100px
    height: 100px
    background-clip: content-box
    padding: 10px
    border: 10px solid violet

@keyframes circle
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.rotation
    background: violet
    width: 200px
    height: 200px
    margin: auto
    transition: 2s ease

.rotation:hover
    background: red
    transform: rotate(360deg)
    border-radius: 50%

.loader
    display: flex
    gap: 10px
    justify-content: center
    align-items: center
    margin: 20px

.dot
    background: purple
    height: 20px
    width: 20px
    border-radius: 50%
    animation: up-down 1s ease infinite alternate

.dot:nth-child(2)
    animation-delay: 0.1s

.dot:nth-child(3)
    animation-delay: 0.2s

.dot:nth-child(4)
    animation-delay: 0.3s

@keyframes up-down
    0%
        transform: translateY(-50%)
    100%
        transform: translateY(50%)
