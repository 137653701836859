@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&family=Spicy+Rice&display=swap');

.label label {
	color: #6b7c93;
	font-weight: 300;
	letter-spacing: 0.025em;
	width: 100%;
	max-width: 500px;
	min-width: 280px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}

.form {
	border: 1px solid #eceaee;
	border-radius: 12px;
	padding: 1.5rem;
	height: auto;
	font-weight: 600;
	font-size: 16px;
	max-width: 550px;
}

.name {
	display: block;
	margin: 10px 0 20px 0;
	width: 100%;
	padding: 10px 14px;
	font-size: 1em;
	font-family: 'Source Code Pro', monospace;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border: 0;
	outline: 0;
	border-radius: 4px;
	background: white;
}

:global {
	.StripeElement {
		display: block;
		margin: 10px 0 20px 0;
		width: 100%;
		padding: 10px 14px;
		font-size: 1em;
		font-family: 'Source Code Pro', monospace;
		box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
		border: 0;
		outline: 0;
		border-radius: 4px;
		background: white;
	}

	input::placeholder {
		color: #aab7c4;
	}

	input:focus,
	.StripeElement--focus {
		box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
		-webkit-transition: all 150ms ease;
		transition: all 150ms ease;
	}
}
