.active-switch-btn
    border-radius: 100px !important
    width: 111px
    height: 38px
    font-size: 12px
    border: 0px
    background: #00B3FF
    cursor: pointer

.active-switch-txt
    position: relative
    top: 10px
    font-weight: 600
    font-family: 'Red Hat Display'
    color: white

.passive-switch-txt
    position: relative
    top: 10px
    font-weight: 600
    font-family: 'Red Hat Display'
    color: #84818A

.passive-switch-btn
    border-radius: 100px !important
    width: 111px
    height: 38px
    font-size: 12px
    background: #F5F5F5
    border: 0px
    cursor: pointer

.aero-switch-wrapper
    display: flex
    justify-content: center
    background: #F5F5F5
    width: fit-content
    margin: auto
    border-radius: 100px
