@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&family=Spicy+Rice&display=swap')

.subheading
    font-family: 'Red Hat Display'
    font-size: 14px
    font-weight: 400
    color: #47464A
    margin: auto
    padding-top: 15px

.faq-wrapper
    background: #F5F5F5
    border-radius: 8px
    padding: 24px

.detail-wrapper
    border: 1px solid #ECEAEE
    border-radius: 8px
    padding: 36px

.detail-heading
    font-size: 20px
    font-weight: 700
    font-family: 'Red Hat Display'
    color: #47464A
    margin-bottom: 20px

.detail-question
    font-size: 16px
    font-weight: 700
    font-family: 'Red Hat Display'
    color: #47464A
    margin-bottom: 10px

.detail-answer
    font-size: 14px
    font-weight: 400
    font-family: 'Red Hat Display'
    color: #47464A
    margin-bottom: 20px

.note-wrapper
    width: 550px
    border-radius: 12px
    border: 1px solid #00B3FF
    background: #F1F1F2
    padding: 26px
    color: #47464A
    font-family: 'Red Hat Display'
    display: flex

.warning-icon
    font-size: 22px
    color: rgb(0, 179, 255)
    position: relative
    bottom: 32px
    margin-right: 10px

.content-wrapper
    margin-top: 30px
@media screen and ( min-width:  1180px)
    .subheading
        width: 40%
    .content-wrapper
        display: flex
        justify-content: space-around
    .faq-wrapper
        width: 330px
    .detail-wrapper
        width: 622px
@media screen and (max-width: 1180px)
    .detail-wrapper
        margin-top: 20px
