.aero-modal{
    
    //  modal header ( over-riding the class which belongs to antd library)
    .ant-modal-header {
         border-radius: 12px 12px 0 0;

    }
    //  modal body ( over-riding the class which belongs to antd library)
    .ant-modal-content {
        border-radius: 12px 12px 12px 12px;
    }
    
}


