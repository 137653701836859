@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&family=Spicy+Rice&display=swap')

.subscription-box-wrapper
    border: 1px solid #ABA8B0
    border-radius: 12px
    padding: 25px

.subscription-title
    font-family: 'Red Hat Display'
    font-size: 20px
    font-weight: 700
    color: #47464A
    display: flex
    justify-content: space-between

.modal-subscription-title
    font-family: 'Red Hat Display'
    font-size: 20px
    font-weight: 700
    color: #47464A
    display: flex

.subscription-price-usd
    font-weight: 700
    color: #00B3FF

.subscription-price
    font-family: 'Red Hat Display'
    font-size: 28px
    font-weight: 700
    color: #00B3FF

.subscription-price-duration
    font-family: 'Red Hat Display'
    font-size: 14px
    color: #84818A
    position: relative
    top: 5px
    left: 5px

.susbcription-box-general-items
    color: #47464A
    font-size: 14px
    margin-bottom: 33px

.subscription-discount-box
    width: 91px
    height: 36px
    background: #FFD363
    border-radius: 109px
    position: relative
    top: 20px

.subscription-discount-box-txt
    position: relative
    top: 6px
    left: 10px
    font-size: 16px

.subscription-description-items
    font-size: 16px
    color: #84818A
    font-weight: 600
    font-family: 'Red Hat Display'

.free-modal-header
    font-size: 24px
    font-family: 'Red Hat Display'
    margin-top: 20px
    margin-bottom: 10px

.free-modal-general-items
    font-size: 14px
    color: #47464A
    margin: 20px

.details-box
    border-radius: 12px
    border: 1px solid #ABA8B0
    padding: 18px 20px 18px 20px

.free-details-box
    border-radius: 12px
    border: 1px solid #ABA8B0
    padding: 18px 20px 18px 20px

.details-box-top-txt
    font-size: 14px
    color: #84818a
    margin-bottom: 10px

.details-box-top-sub-txt
    color: #84818a
    margin-bottom: 10px

.details-box-btn
    background: #FFFFFF
    border: 2px solid #00B3FF
    color: #00B3FF
    margin-top: 15px

.warning-banner-wrapper
    background: rgb(249, 120, 85)
    color: white
    border-radius: 12px
    padding: 26px
    margin-bottom: 25px

.gst-text
    color: #84818A
    font-size: 14px
    font-weight: 400
    font-family: 'Red Hat Display'
    position: relative
    top: 5px
    left: 10px

.selected-plan-wrapper
    border: 1px solid #00B3FF
    background: #EAF9FF

.feature-description-box
    border-radius: 8px
    background: #F8F8F8
    padding: 10px
    margin-top: 15px
    color: #84818A

.feature-labels
    width: 140px

.feature-wrappers
    display: flex
    justify-content: space-between
    padding: 10px

.billing-cycle-box
    border: 1px solid #ABA8B0
    border-radius: 8px
    width: 344px
    padding: 12px 16px 12px 16px
    margin-top: 25px

.best-choice-plan
    display: flex
    background: #6CBE44
    color: white
    border-radius: 100px
    padding: 12px 10px 6px 10px
    font-size: 16px
    margin-left: 15px

.billing-cycle-title
    font-size: 20px
    font-weight: 700
    margin-top: 10px

.plan-duration-txt
    color: #47464A
    font-size: 16px
    font-weight: 700

.billing-discount-box
    background: #FFD363
    border-radius: 109px
    padding: 6px 10px 6px 10px
    font-size: 16px

.plan-selection-btn-wrapper
    text-align: center
    margin: 15px

.top-right-date-content
    font-size: 12px
    color: #84818a
    position: relative

@media screen and ( min-width:  1427px)
    .plan-radio-grp
        display: flex !important
    .mobile-view
        display: none
    .list-wrapper
        display: flex
        justify-content: center
        margin-right: 185px
    .mobile-list-desc
        display: none
    .subscription-box-wrapper
        width: 205px
        height: 600px
    .choose-plan-btn
        margin-left: -15px
        width: 176px
    .subscription-discount-box
        left: 57px
    .top-section-wrapper
        display: flex
        margin-top: 25px
    .gigapixel-box
        width: 168px
    .details-box
        margin-right: 15px
    .storage-box
        width: 399px
    .plan-box
        width: 440px
    .details-box
        height: 161px
    .storage-text
        display: flex
    .details-box-btn
        width: 350px
    .progress-bar
        width: 144px !important
    .free-gigapixel-box
        width: 208px
    .free-details-box
        height: 99px
        margin-right: 15px
    .free-plan-box
        width: 580px
    .list-margin
        margin-left: 15px
    .plan-selection-btn
        width: 650px
    .feature-description-box
        width: 377px
    .other-palns-btn
        width: 182.5px
    .drawer-subscription-head-wrapper
        display: flex
        justify-content: space-between
        align-items: center
    .drawer-subscription-header
        flex-grow: 1
        transform: translateX(60px)
        text-align: center
        font-size: 24px
    .top-right-date-content
        left: 35px
    .details-box-top-sub-txt
        font-size: 14px
    .top-right-box-wrapper
        display: flex

@media screen and ( max-width:  1427px)
    .plan-radio-grp
        display: flex !important
        flex-wrap: wrap
        gap: 30px
        justify-content: center
    .web-view
        display: none
    .list-wrapper
        display: flex
        justify-content: center
        flex-wrap: wrap
        gap: 30px
    .susbcription-box-general-items
        display: flex
        justify-content: space-between
    .mobile-list-desc
        color: #ABA8B0
        width: 116px
    .subscription-box-wrapper
        width: 328px
        height: 745px
    .choose-plan-btn
        width: 100%
    .subscription-discount-box
        left: 115px
    .top-section-wrapper
        margin-top: 25px
    .gigapixel-box
        margin-bottom: 20px
        height: 82px
    .storage-box
        margin-bottom: 20px
    .plan-box
        margin-bottom: 20px
    .gigapixel-box-top-txt-mobile
        font-size: 14px
        color: #84818a
        margin-left: 20px
        margin-top: 10px
    .details-box-btn
        width: 100%
    .progress-bar
        width: 58vw !important
    .free-gigapixel-box
        margin-bottom: 20px
    .plan-selection-btn
        width: 90%
        position: fixed
        bottom: 20px
        transform: translateX(-50%)
        box-shadow: 0px 9px 11.6px 0px #0000001F
    .feature-description-box
        width: 90%
    .other-palns-btn
        width: 45%
    .drawer-subscription-header
        text-align: center
        font-size: 24px
    .skip-plans-btn
        text-align: center
    .top-right-date-content
        left: 5px
    .details-box-top-sub-txt
        font-size: 11px
    .top-right-box-wrapper
        display: flex
        justify-content: space-between
