@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&family=Spicy+Rice&display=swap')
@import '../node_modules/antd/dist/antd.css'
@import './aero.colors.sass'

html
  font-size: 100%

body
  margin: 0
  padding: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif
  font-size: 1rem
  line-height: 1.5

button:disabled
  opacity: 0.5

#root
  height: 100%

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

.module-title
  font-size: 20px
  font-weight: 700
  color: #47464A
  font-family: 'Red Hat Display'

.ant-steps-item-description
  width: 140px

@media screen and ( min-width:  1180px)
  .app-screen-mobile
    display: none
  .module-container
    margin: 30px

@media screen and (max-width: 1180px)
  .app-screen
    display: none
  .module-container
    margin: 15px
