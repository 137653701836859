@media screen and ( min-width:  1180px)
    .profile-wrapper
        width: 694px
        margin: 50px
    .profile-name-wrapper
        display: flex
        justify-content: space-between
    .name-input
        width: 300px
    .country-input
        width: 43%
@media screen and (max-width: 1180px)
    .profile-wrapper
        margin: 15px
    .name-input
        width: 100%
    .country-input
        width: 100%
